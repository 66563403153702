import * as React from 'react';
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Button from "@mui/joy/Button";
import {Card, CircularProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {GetAllFranchises, GetUser, LogoutUser} from "../../axios/Axios";
import Box from "@mui/joy/Box";

interface Location {
    label: string;
    hasPermission: boolean;
    hasPermissionLabel: string;
}

interface User {
    first_name: string;
    last_name: string;
    location_permissions: string[];
}

interface Franchise {
    location: string;
}

export default function SelectLocation() {
    let navigate = useNavigate()
    const [options, setOptions] = React.useState<Location[]>([]);
    const [value, setValue] = React.useState<any>('All');
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState<User>({first_name: '', last_name: '', location_permissions: ['']});
    const [franchises, setFranchises] = useState<Franchise[]>([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await GetUser();
                if (userResponse.status === 200) {
                    setUser(userResponse.data);
                    const franchisesResponse = await GetAllFranchises();
                    setFranchises(franchisesResponse.data);
                    setLoaded(true);
                }
            } catch (error) {
                await LogoutUser();
                navigate("/");
            }
        };
        fetchData();
    }, [setLoaded]);

    useEffect(() => {
        const updateFranchiseList = () => {
            let optionListNew: Location[] = [];
            franchises.push({location: 'All'})
            franchises.forEach((franchise) => {
                let permissionLabel = user?.location_permissions.includes('franchise.location.' + franchise.location.toLowerCase().replaceAll(' ', '_'));
                if (user?.location_permissions.includes('franchise.location.all')) {
                    permissionLabel = true;
                }
                optionListNew.push({
                    label: franchise.location,
                    hasPermission: permissionLabel ?? false,
                    hasPermissionLabel: permissionLabel ? 'Your Locations' : 'Other Locations'
                });
            });
            setOptions(optionListNew)
        };

        if (franchises.length > 0 && user) {
            updateFranchiseList();
        }
    }, [franchises, user]);


    function loadDashboard() {
        localStorage.setItem('location', value)
        navigate('/dashboard')
    }

    function handleInputChange(event: any, value: any) {
        setValue(value)
    }

    if (!loaded) {
        return <Box sx={{position: 'absolute', left: '50%', top: '50%'}}>
            <Box sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100%',
                height: '100%',
                justifyContent: 'center'
            }}>
                <CircularProgress
                    size="lg"
                />
            </Box>
        </Box>;
    }


    return (

        <Card
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '400px'
            }}>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    loadDashboard()
                }}
            >
                <FormControl required>
                    <FormLabel>Select Yeti Clean Franchise Location</FormLabel>
                    <Autocomplete
                        onInputChange={handleInputChange}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        options={options.sort((a, b) => -String(a.hasPermissionLabel).localeCompare(String(b.hasPermissionLabel)))}
                        groupBy={(option) => String(option.hasPermissionLabel)}
                        getOptionLabel={(option) => option.label}
                        getOptionDisabled={(option) =>
                            option.hasPermission == false
                        }
                        renderOption={(optionProps, option) => (
                            <AutocompleteOption {...optionProps} >
                                {option.hasPermission &&
                                    <Typography color="success">{option.label}</Typography>}
                                {!option.hasPermission &&
                                    <Typography color="danger">{option.label}</Typography>}
                            </AutocompleteOption>
                        )}
                        slotProps={{
                            input: {
                                autoComplete: 'new-password',
                            },
                        }}></Autocomplete>
                    <Button type="submit" sx={{my: 2}}>Load Dashboard</Button>
                </FormControl>
            </form>
        </Card>
    );
}